import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'

// import Bio from "../components/bio"
import styled, { css } from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'
// import { rhythm, scale } from "../utils/typography"

// ;<Layout>
//   <Row>
//     <MarkDownContent
//       className="blog-post-content"
//       dangerouslySetInnerHTML={{
//         __html: data.allMarkdownRemark.edges[0].node.html,
//       }}
//     />
//   </Row>
// </Layout>

const sizes = {
  desktop: 992,
  tablet: 768,
  phone: 576,
}

// Iterate through the sizes and create a media template
export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)};
    }
  `

  return acc
}, {})

const Container = styled.div`
  /* margin: 2em auto; */
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.appText};
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  z-index: 1;
  max-width: 750px;
  margin: auto;
  background-color: white;
`

const MarkDownContent = styled.div`
  margin: 1em auto;
  font-size: 1.19em;
  line-height: 1.37em;
  padding: 2em;
  ${'' /* background-color: ${({ theme }) => 'white'}; */}
  ${media.phone`margin: 0;`};
  max-width: 750px;
`

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    return (
      <Layout location={this.props.location} title={siteTitle} color={'white'}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <Container>
          <h1>{post.frontmatter.title}</h1>
          <p
            style={{
              // ...scale(-1 / 5),
              display: `block`,
              // marginBottom: rhythm(1),
              // marginTop: rhythm(-1),
            }}
          >
            {post.frontmatter.date}
          </p>
          <Img fluid={post.frontmatter.banner.childImageSharp.fluid} />
          {post.frontmatter.bannerCredit && (
            <div>{post.frontmatter.bannerCredit}</div>
          )}

          <MarkDownContent
            className="blog-post-content"
            dangerouslySetInnerHTML={{
              __html: post.html,
            }}
          />
        </Container>
        {/* <div dangerouslySetInnerHTML={{ __html: post.html }} /> */}
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        banner {
          ...bannerImage640
        }
        bannerCredit
      }
    }
  }
`
